import { createStoreModule } from '../utils';
import api from '@/api';

const FIGMA_REDIRECT_URI = `${process.env.APP_BASE_URL}/figma/callback`;

export default createStoreModule({
  name: 'figmaOAuth',
  modelName: 'figma_oauth',
  state: {
    callbackUrl: localStorage.getItem('figmaCallbackUrl') || null,
    OAuthState: localStorage.getItem('figmaOAuthState') || null
  },
  mutations: {
    setCallbackUrl(state, callbackUrl) {
      state.callbackUrl = callbackUrl;
      localStorage.setItem('figmaCallbackUrl', callbackUrl);
    },
    setOAuthState(state, OAuthState) {
      state.OAuthState = OAuthState;
      localStorage.setItem('figmaOAuthState', OAuthState);
    }
  },
  actions: {
    async generateFigmaAccessToken({ state }, { code, OAuthState }) {
      if (OAuthState !== state.OAuthState) {
        throw new Error('Invalid Figma OAuth state');
      }
      await api.post('/figma_token/generate', { code, state: OAuthState, redirect_uri: FIGMA_REDIRECT_URI });
    },
    generateFigmaOAuthUrl({ commit }, { callbackUrl }) {
      const params = {
        state: Math.random().toString(36).substring(2, 15),
        scope: 'files:read',
        response_type: 'code',
        redirect_uri: FIGMA_REDIRECT_URI,
        client_id: `${process.env.FIGMA_CLIENT_ID}`
      };

      commit('setOAuthState', params.state);
      commit('setCallbackUrl', callbackUrl);

      const queryString = Object.entries(params)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        .join('&');

      const url = `https://www.figma.com/oauth?${queryString}`;
      return url;
    }
  }
});
